/* eslint-disable relay/unused-fields */
import graphql from "babel-plugin-relay/macro";
import {
  ViewerContextQuery,
  ViewerContextQuery$data,
} from "context/__generated__/ViewerContextQuery.graphql";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import {
  Context,
  createContext,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay";
import { MaybeUndef } from "sps-shared/dist/types/UtilityTypes";
import emptyFunction from "sps-shared/dist/utils/emptyFunction";
import { firebaseAuth } from "utils/firebase/initializeFirebase";

const query = graphql`
  query ViewerContextQuery {
    UserQueries {
      viewer {
        viewer {
          id
          email
          username
        }
      }
    }
  }
`;

type Viewer = Exclude<
  ViewerContextQuery$data["UserQueries"]["viewer"]["viewer"],
  null
>;

export type ViewerContextData = {
  isLoggingIn: boolean;
  setIsLoggingIn: (val: boolean) => void;
  // Undefined if viewer has not been loaded yet. Null if viewer is not logged in.
  viewer: MaybeUndef<Viewer>;
  viewerFirebaseInfo: MaybeUndef<FirebaseUser>;
};

export const ViewerContext: Context<ViewerContextData> =
  createContext<ViewerContextData>({
    isLoggingIn: false,
    setIsLoggingIn: emptyFunction,
    viewer: undefined,
    viewerFirebaseInfo: undefined,
  });

function LoadQuery({
  setViewer,
}: {
  setViewer: (val: MaybeUndef<Viewer>) => void;
}) {
  const {
    UserQueries: {
      viewer: { viewer },
    },
  } = useLazyLoadQuery<ViewerContextQuery>(query, {});
  useEffect(() => {
    setViewer(viewer);
  }, [setViewer, viewer]);

  return null;
}

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function ViewerContextProvider(props: ProviderProps): JSX.Element {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [viewer, setViewer] = useState<MaybeUndef<Viewer>>(undefined);
  const [viewerFirebaseInfo, setViewerFirebaseInfo] =
    useState<MaybeUndef<FirebaseUser>>(undefined);
  const value = useMemo(
    () => ({ isLoggingIn, setIsLoggingIn, viewer, viewerFirebaseInfo }),
    [isLoggingIn, viewer, viewerFirebaseInfo]
  );
  onAuthStateChanged(firebaseAuth, (user) => {
    setViewerFirebaseInfo(user);
  });

  return (
    <ViewerContext.Provider value={value}>
      <Suspense fallback={null}>
        <LoadQuery setViewer={setViewer} />
      </Suspense>
      {props.children}
    </ViewerContext.Provider>
  );
}
