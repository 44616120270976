import { SeverityLevel } from "@sentry/react";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import getDefaultLogProperties from "utils/analytics/getDefaultLogProperties";
import getErrorEventSeverity from "utils/analytics/getErrorEventSeverity";
import logEvent from "utils/analytics/logEvent";
import logSentryError from "utils/analytics/logSentryError";
import toObject from "sps-shared/dist/utils/object/toObject";
import getErrorPropertiesToLog from "sps-shared/dist/utils/analytics/getErrorPropertiesToLog";
import getEnvironment from "utils/env/getEnvironment";
import Environment from "sps-shared/dist/types/enums/Environment";

export default function logError(
  event: AnalyticsEvent,
  error: Error | string,
  data?: { [key: string]: any },
  severityOverride?: SeverityLevel
) {
  if (getEnvironment() !== Environment.Production) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  const errorProperties = getErrorPropertiesToLog(error);

  const severity =
    severityOverride != null
      ? severityOverride
      : getErrorEventSeverity(event, error);

  const properties = {
    ...(data == null ? {} : toObject(data)),
    ...errorProperties,
    severity,
  };
  logEvent(event, properties);
  logSentryError(
    event,
    error,
    {
      ...properties,
      event,
      ...getDefaultLogProperties(),
    },
    severity
  );
}
