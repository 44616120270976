import Environment from "sps-shared/dist/types/enums/Environment";
import isDevSubdomain from "utils/env/isDevSubdomain";
import isLocalhost from "utils/env/isLocalhost";

export default function getEnvironment(): Environment {
  if (isLocalhost()) {
    return Environment.Local;
  }

  if (isDevSubdomain()) {
    return Environment.Development;
  }

  return process.env.NODE_ENV as Environment;
}
