import { Maybe, MaybeUndef } from "sps-shared/dist/types/UtilityTypes";

import BackgroundOverlay from "components/loading/BackgroundOverlay";
import CloseButton from "components/buttons/CloseButton";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import OutsideClickHideModal from "components/modals/OutsideClickHideModal";
import ReactDOM from "react-dom";
import joinClasses from "utils/joinClasses";
import styles from "components/modals/css/Modal.module.css";
import { RefObject } from "react";
import FontClass from "types/enums/FontClass";
import BottomDrawer from "components/drawers/BottomDrawer";
import ElementId from "types/enums/ElementId";
import useBreakpoints from "hooks/dimensions/useBreakpoints";
import useDisableBodyScroll from "hooks/useDisableBodyScroll";
import Body1 from "components/text/Body1";

type Props = {
  bottomDrawerHeight?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className?: string;
  description?: string | JSX.Element;
  disableResponsiveContainerBottomDrawer?: boolean;
  excludeRefs?: Array<RefObject<HTMLElement>>;
  footer?: MaybeUndef<JSX.Element>;
  hideCloseButton?: boolean;
  hideWithVisibility?: boolean;
  isShown: boolean;
  modalId?: ElementId;
  onHide: () => void;
  title?: string | JSX.Element;
  titleFontClass?: FontClass;
};

export default function Modal({
  bottomDrawerHeight,
  children,
  className,
  description,
  disableResponsiveContainerBottomDrawer = false,
  excludeRefs,
  footer,
  hideCloseButton = false,
  hideWithVisibility = false,
  isShown,
  modalId,
  onHide,
  title,
  titleFontClass = FontClass.Header2,
}: Props): Maybe<JSX.Element> {
  const { isWidth768Breakpoint } = useBreakpoints();
  // Disable outer scroll when modal is visible.
  useDisableBodyScroll(isShown);

  if (!isShown && !hideWithVisibility) {
    return null;
  }

  if (isWidth768Breakpoint) {
    return (
      <BottomDrawer
        bottomDrawerHeight={bottomDrawerHeight}
        disableResponsiveContainer={disableResponsiveContainerBottomDrawer}
        isShown={isShown}
        onHide={onHide}
        title={title}
      >
        {children}
      </BottomDrawer>
    );
  }

  const closeRow = !hideCloseButton && (
    <div className={styles.closeRow}>
      <CloseButton colorValue={ColorValue.White} onClick={onHide} />
    </div>
  );

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: window.scrollY,
        visibility: !isShown && hideWithVisibility ? "hidden" : "visible",
      }}
    >
      {isShown && <BackgroundOverlay />}
      {/* Need this extra container to make it so closeRow still shows up, but when modal is scrollable the border radius applies */}
      <div className={styles.containerInner}>
        {closeRow}
        <OutsideClickHideModal
          className={styles.outsideClick}
          hideModal={onHide}
          excludeRefs={excludeRefs}
          onBackgroundOverlayOnly
        >
          <div
            id={modalId}
            className={joinClasses(styles.modal, className)}
            style={{ maxHeight: hideCloseButton ? "95vh" : "85vh" }}
          >
            {title != null && (
              <div className={styles.title}>
                {typeof title === "string" ? (
                  <div
                    className={joinClasses(ColorClass.Primary, titleFontClass)}
                    style={{ textAlign: "center" }}
                  >
                    {title}
                  </div>
                ) : (
                  title
                )}
                {description != null &&
                  (typeof description === "string" ? (
                    <Body1 colorClass={ColorClass.Primary}>{description}</Body1>
                  ) : (
                    description
                  ))}
              </div>
            )}
            {children}
            {footer != null && <div className={styles.footer}>{footer}</div>}
          </div>
        </OutsideClickHideModal>
      </div>
    </div>,
    document.body
  );
}
