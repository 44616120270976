/**
 * @generated SignedSource<<5395543a92fa0eeaf9845e110de3d184>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from "relay-runtime";
export type ViewerContextQuery$variables = {};
export type ViewerContextQuery$data = {
  readonly UserQueries: {
    readonly viewer: {
      readonly viewer: {
        readonly email: string | null;
        readonly id: string;
        readonly username: string;
      } | null;
    };
  };
};
export type ViewerContextQuery = {
  response: ViewerContextQuery$data;
  variables: ViewerContextQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: "UserQueriesResponse",
      kind: "LinkedField",
      name: "UserQueries",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ViewerResponse",
          kind: "LinkedField",
          name: "viewer",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Viewer",
              kind: "LinkedField",
              name: "viewer",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "email",
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "username",
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "ViewerContextQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null,
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "ViewerContextQuery",
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: "d62ef729b54782038dfcc7fdcbc37d23",
      id: null,
      metadata: {},
      name: "ViewerContextQuery",
      operationKind: "query",
      text: "query ViewerContextQuery {\n  UserQueries {\n    viewer {\n      viewer {\n        id\n        email\n        username\n      }\n    }\n  }\n}\n",
    },
  };
})();

(node as any).hash = "1a8b4e55ba26640ac449bb110c02e31a";

export default node;
