import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/react";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import getErrorPropertiesToLog from "sps-shared/dist/utils/analytics/getErrorPropertiesToLog";

function getFingerprint(event: AnalyticsEvent, e: Error | string) {
  if (event === AnalyticsEvent.TopLevelEvent) {
    return [event, getErrorPropertiesToLog(e).errorMessage];
  }
  return [event];
}

export default function logSentryError(
  event: AnalyticsEvent,
  e: Error | string,
  extra?: { [key: string]: any },
  severity: SeverityLevel = "error"
) {
  Sentry.captureException(typeof e === "string" ? `${event}—${e}` : e, {
    extra,
    fingerprint: getFingerprint(event, e),
    level: severity,
    tags: {
      event,
      eventSource: "frontend",
    },
  });
}
