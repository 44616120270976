import ButtonWithText from "components/buttons/ButtonWithText";
import TextInput from "components/inputs/text/TextInput";
import FlexBox from "components/layout/FlexBox";
import notifyError from "components/toast/notifyError";
import useViewerContext from "hooks/contexts/useViewerContext";
import { useState } from "react";
import invariant from "tiny-invariant";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import LoginOrRegister from "types/LoginOrRegister";
import firebaseLogin from "utils/firebase/auth/firebaseLogin";
import postLoginEmail from "utils/rest/login/postLoginEmail";

type Props = {
  loginOrRegister: LoginOrRegister;
};

/**
 * A form that allows users to input a nonce that was received by email.
 *
 * For passwordless login, i.e. email nonce login.
 *
 * Used for both login and registration.
 */
export default function LoginOrRegisterNonceForm({ loginOrRegister }: Props) {
  const [nonce, setNonce] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoggingIn } = useViewerContext();

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={24}>
      <TextInput
        label="Nonce"
        onChange={setNonce}
        placeholder="Enter the emailed code"
        value={nonce}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        disabled={nonce === ""}
        fontClass={FontClass.NavLink}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const data = await postLoginEmail({
            emailNonce: nonce,
          });

          if (data == null) {
            setIsLoading(false);
            notifyError();
            return;
          }

          if (data.firebaseToken != null) {
            try {
              setIsLoggingIn(true);
              await firebaseLogin(data.firebaseToken);
            } catch (e) {
              setIsLoggingIn(true);
              notifyError((e as Error).message);
              return;
            }
          }

          if (data.redirect != null) {
            window.location.href = data.redirect;
            return;
          }

          invariant(data.errorDescription != null);
          setIsLoading(false);
          notifyError(data.errorDescription, data.errorMessage);
        }}
      >
        {loginOrRegister === "login" ? "Sign In" : "Create Account"}
      </ButtonWithText>
    </FlexBox>
  );
}
