import BodyText from "components/text/BodyText";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import commonStyles from "components/inputs/text/css/InputCommon.module.css";
import joinClasses from "utils/joinClasses";
import styles from "components/inputs/text/css/TextInput.module.css";
import ColorClass from "types/enums/ColorClass";
import stringNumBytes from "utils/string/stringNumBytes";
import { useState } from "react";
import FlexBox from "components/layout/FlexBox";
import HideIfEmpty from "components/containers/HideIfEmpty";
import { Maybe } from "sps-shared/dist/types/UtilityTypes";
import InputWithLabels from "components/inputs/InputWithLabels";

const BUTTON_INNER_RIGHT = 16;
const FLEX_GAP = 16;

type Props = {
  autoFocus?: boolean;
  // Appears on the inside of the input's right side
  buttonInner?: JSX.Element;
  // Appears on the outside of the input's right side
  buttonOuter?: JSX.Element;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  label?: JSX.Element | string;
  maxLength?: number;
  onChange: (val: string) => void;
  onPressEnter?: () => void;
  permaPlaceholder?: string | JSX.Element;
  placeholder?: string;
  readOnly?: boolean;
  showMaxLengthHint?: boolean;
  subLabel?: JSX.Element | string;
  value: string;
};

export default function TextInput({
  autoFocus = false,
  buttonInner,
  buttonOuter,
  className,
  disabled,
  hasError = false,
  label,
  maxLength,
  onChange,
  onPressEnter,
  permaPlaceholder,
  placeholder,
  readOnly,
  showMaxLengthHint = true,
  subLabel,
  value,
}: Props): JSX.Element {
  const hint =
    maxLength != null && showMaxLengthHint
      ? `${maxLength - stringNumBytes(value)} characters left`
      : undefined;
  const [buttonOuterRef, setButtonOuterRef] =
    useState<Maybe<HTMLDivElement>>(null);

  return (
    <InputWithLabels hint={hint} label={label} subLabel={subLabel}>
      <div className={styles.container}>
        {permaPlaceholder && typeof permaPlaceholder === "string" && (
          <BodyText
            className={styles.permaPlaceholder}
            colorClass={ColorClass.Ghost}
            fontClass={FontClass.Body1}
          >
            {permaPlaceholder}
          </BodyText>
        )}
        {permaPlaceholder && typeof permaPlaceholder !== "string" && (
          <div className={styles.permaPlaceholder}>{permaPlaceholder}</div>
        )}
        {buttonInner && (
          <div
            className={joinClasses(styles.buttonInner, GlobalClass.HideText)}
            style={{
              right:
                buttonOuterRef == null
                  ? BUTTON_INNER_RIGHT
                  : buttonOuterRef.offsetWidth + BUTTON_INNER_RIGHT + FLEX_GAP,
            }}
          >
            {buttonInner}
          </div>
        )}
        <FlexBox alignItems="center" gap={FLEX_GAP}>
          <input
            disabled={disabled}
            readOnly={readOnly}
            className={joinClasses(
              commonStyles.textInput,
              className,
              FontClass.Body1,
              hasError ? commonStyles.textInputError : null,
              disabled === true ? commonStyles.disabled : undefined
            )}
            onChange={(e) => {
              const val = e.target.value;
              if (maxLength != null && stringNumBytes(val) > maxLength) {
                return;
              }

              onChange(val);
            }}
            onKeyDown={(key) => {
              if (key.code === "Enter" && onPressEnter !== undefined) {
                onPressEnter();
              }
            }}
            ref={(val) => {
              if (autoFocus) {
                setTimeout(() => val?.focus(), 0);
              }
            }}
            placeholder={placeholder}
            type="text"
            value={value}
          />
          <HideIfEmpty ref={(val) => setButtonOuterRef(val)}>
            {buttonOuter}
          </HideIfEmpty>
        </FlexBox>
      </div>
    </InputWithLabels>
  );
}
