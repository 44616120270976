import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import PlainButton from "components/buttons/PlainButton";
import joinClasses from "utils/joinClasses";
import styles from "components/bottom-tabs/css/BottomTabButton.module.css";
import { Link } from "react-router-dom";

export default function BottomTabButton({
  children,
  className,
  href,
  icon,
}: {
  children: any;
  className?: string;
  href: string;
  icon: JSX.Element;
}): JSX.Element {
  const isActive = window.location.href.includes(href);

  return (
    <Link className={styles.link} to={href}>
      <PlainButton
        className={joinClasses(
          styles.tabButton,
          isActive ? styles.tabButtonActive : null,
          className
        )}
      >
        {icon}
        <Body2
          colorClass={isActive ? ColorClass.Primary : ColorClass.Secondary}
          className={styles.tabButtonText}
        >
          {children}
        </Body2>
      </PlainButton>
    </Link>
  );
}
