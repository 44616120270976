import { MaybeUndef } from "sps-shared/dist/types/UtilityTypes";
import { Variables } from "react-relay";
import CustomHeader from "sps-shared/dist/types/enums/CustomHeader";

export default async function fetchGraphql(
  name: string,
  text: MaybeUndef<string>,
  variables: Variables
): Promise<Response> {
  return fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
    body: JSON.stringify({
      operationName: name,
      query: text,
      variables,
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      [CustomHeader.OperationName]: name,
    },
    method: "POST",
  });
}
