/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { Maybe } from "sps-shared/dist/types/UtilityTypes";
import emptyFunction from "sps-shared/dist/utils/emptyFunction";

type Status = "error" | "idle" | "loading" | "ready";

/**
 * Adapted from https://usehooks.com/useScript/.
 */
export default function useScript(
  src: string,
  attributes?: {
    crossorigin?: string;
    defer?: boolean;
    nonce?: string;
    referrerpolicy?: string;
  }
): Status {
  // Keep track of script status
  const [status, setStatus] = useState<Status>(src ? "loading" : "idle");

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return emptyFunction;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script: Maybe<HTMLScriptElement> = document.querySelector(
        `script[src="${src}"]`
      );

      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");

        // Add additional attributes
        if (attributes?.defer === true) {
          script.defer = true;
        }
        if (attributes?.crossorigin != null) {
          script.setAttribute("crossorigin", attributes.crossorigin);
        }
        if (attributes?.nonce != null) {
          script.setAttribute("nonce", attributes.nonce);
        }

        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: any) => {
          script!.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
        };

        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script!.getAttribute("data-status") as Status);
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: any) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };

      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [attributes?.crossorigin, attributes?.defer, attributes?.nonce, src] // Only re-run effect if script src changes
  );

  return status;
}
