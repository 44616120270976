import UrlParam from "sps-shared/dist/types/enums/UrlParam";
import isProd from "utils/env/isProd";
import getUrlParam from "utils/url/getUrlParam";

export default function consoleLogErrorIfNotProd(e: Error) {
  if (isProd() && getUrlParam(UrlParam.EnableErrorLogs) !== "1") {
    return;
  }
  // eslint-disable-next-line no-console
  console.error(e);
}
