import axios from "axios";
import { Maybe } from "sps-shared/dist/types/UtilityTypes";
import getRestUrl from "utils/env/getRestUrl";

type ResponseBody = {
  success: boolean;
};

export default async function postLogout(): Promise<Maybe<ResponseBody>> {
  try {
    const { data } = await axios.post<ResponseBody>(
      getRestUrl("logout"),
      {},
      {
        // Needed so session cookie gets unset
        withCredentials: true,
      }
    );
    return data;
  } catch {
    return null;
  }
}
