import { Maybe } from "sps-shared/dist/types/UtilityTypes";
import Dimensions from "types/Dimensions";
import getVideoDimensions from "utils/assets/getVideoDimensions";
import getImageDimensions from "utils/assets/getImageDimensions";

export default async function getAssetDimensions(
  file: File,
  fileDataUrl: string
): Promise<Maybe<Dimensions>> {
  if (file.type.includes("video")) {
    return getVideoDimensions(fileDataUrl);
  }
  if (file.type.includes("image")) {
    return getImageDimensions(fileDataUrl);
  }
  return null;
}
