import {
  DropEvent,
  ErrorCode,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import {
  Maybe,
  MaybeUndef,
  PartialRecord,
} from "sps-shared/dist/types/UtilityTypes";
import joinClasses from "utils/joinClasses";
import styles from "components/inputs/css/Dropzone.module.css";
import { useState } from "react";
import emptyFunction from "sps-shared/dist/utils/emptyFunction";
import Dimensions from "types/Dimensions";
import bytesToHumanReadableSize from "utils/bytes/bytesToHumanReadableSize";
import message from "components/toast/message";
import pluralize from "sps-shared/dist/utils/string/pluralize";
import NotifyErrorDescription from "types/enums/NotifyErrorDescription";
import megabytesToBytes from "utils/bytes/megabytesToBytes";
import MediaType from "types/enums/MediaType";
import HUMAN_READABLE_MEDIA_TYPE from "constants/HumanReadableMediaType";
import getImageDimensions from "utils/assets/getImageDimensions";

// TODO: may want to support multiple files
const MAX_FILES = 1;
type Accept = PartialRecord<MediaType, Array<string>>;

function getAcceptErrorMessage(accept: Accept): string {
  const humanReadableMediaTypes = Object.keys(accept).map(
    (mediaType) => HUMAN_READABLE_MEDIA_TYPE[mediaType]
  );

  if (humanReadableMediaTypes.length === 1) {
    return `Invalid file type, only ${humanReadableMediaTypes[0]} is allowed`;
  }

  const acceptHumanReadable = `${humanReadableMediaTypes
    .slice(0, -1)
    .join(", ")} and ${humanReadableMediaTypes.slice(-1)[0]}`;
  return `Invalid file type, only ${acceptHumanReadable} are allowed`;
}

function getOnDropRejectedErrorMessage(
  fileRejections: Array<FileRejection>,
  accept: Accept,
  maxFiles: number,
  maxSize: number
) {
  if (fileRejections.length === 0 || fileRejections[0].errors.length === 0) {
    return NotifyErrorDescription.UnexpectedError;
  }

  const firstRejection = fileRejections[0];
  const firstError = firstRejection.errors[0];
  switch (firstError.code) {
    case ErrorCode.FileInvalidType: {
      return getAcceptErrorMessage(accept);
    }
    case ErrorCode.FileTooLarge:
      return `File is too big, max size is ${bytesToHumanReadableSize(
        maxSize
      )}`;
    case ErrorCode.FileTooSmall:
      return "File is too small";
    case ErrorCode.TooManyFiles:
      return `Too many files, you can only upload ${maxFiles} ${pluralize(
        "file",
        maxFiles
      )}`;
    default:
      return NotifyErrorDescription.UnexpectedError;
  }
}

type Props = {
  Component?: (props: {
    acceptedFiles: Array<File>;
    imageDimensions?: MaybeUndef<Dimensions>;
  }) => Maybe<JSX.Element>;
  accept: Accept;
  children?: any;
  className?: string;
  disableHoverStyle?: boolean;
  disabled?: boolean;
  maxSize?: number;
  onDropAccepted?: <T extends File>(files: Array<T>, event: DropEvent) => void;
};

export default function Dropzone({
  Component,
  accept,
  children,
  className,
  disableHoverStyle = false,
  disabled = false,
  maxSize = megabytesToBytes(100),
  onDropAccepted = emptyFunction,
}: Props): JSX.Element {
  const [dimensions, setDimensions] = useState<Maybe<Dimensions>>(null);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    // isDragAccept,
    isDragActive,
    // isDragReject,
  } = useDropzone({
    accept,
    disabled,
    maxFiles: MAX_FILES,
    maxSize,
    onDropAccepted: async (files, event) => {
      onDropAccepted(files, event);

      if (files.length === MAX_FILES) {
        const dataUri = URL.createObjectURL(files[0]);
        const imageDimensions = await getImageDimensions(dataUri);
        setDimensions(imageDimensions);
      }
    },
    onDropRejected: (fileRejections) => {
      message({
        content: getOnDropRejectedErrorMessage(
          fileRejections,
          accept,
          MAX_FILES,
          maxSize
        ),
        duration: 5,
        type: "error",
      });
    },
  });

  return (
    <div
      {...getRootProps({
        className: joinClasses(
          styles.dropzone,
          !disableHoverStyle ? styles.dropzoneHover : null,
          isDragActive && !disableHoverStyle ? styles.dragActive : null,
          // TODO: this behavior is broken... waiting for fix.
          // See https://github.com/react-dropzone/react-dropzone/issues/888 for more
          // isDragAccept ? styles.dragAccept : null,
          // isDragReject ? styles.dragReject : null,
          className
        ),
      })}
    >
      <input {...getInputProps()} />
      {Component != null && (
        <Component acceptedFiles={acceptedFiles} imageDimensions={dimensions} />
      )}
      {children}
      <div className={styles.overlayAccept} />
      <div className={styles.overlayReject} />
    </div>
  );
}
