import ColorClass from "types/enums/ColorClass";
import styles from "pages/landing/css/LandingPage.module.css";
import logEvent from "utils/analytics/logEvent";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import logError from "utils/analytics/logError";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import TextButton from "components/buttons/TextButton";
import Header3 from "components/text/Header3";
import PageBody from "components/containers/PageBody";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import { useState } from "react";
import CheckboxButton from "components/buttons/CheckboxButton";
import ToggleButton from "components/buttons/ToggleButton";
import BigToggleButton from "components/buttons/BigToggleButton";
import notify from "components/toast/notify";
import message from "components/toast/message";
import BottomDrawer from "components/drawers/BottomDrawer";
import Header1 from "components/text/Header1";
import { range } from "sps-shared/dist/utils/array/range";
import Modal from "components/modals/Modal";
import RadioButton from "components/buttons/RadioButton";
import ChevronUpIcon from "components/icons/ChevronUpIcon";
import ColorValue from "types/enums/ColorValue";
import ChevronRightIcon from "components/icons/ChevronRightIcon";
import ChevronDownIcon from "components/icons/ChevronDownIcon";
import ChevronLeftIcon from "components/icons/ChevronLeftIcon";
import TextInput from "components/inputs/text/TextInput";
import CrossIcon from "components/icons/CrossIcon";
import TextArea from "components/inputs/text/TextArea";
import bytesToHumanReadableSize from "utils/bytes/bytesToHumanReadableSize";
import Dropzone from "components/inputs/Dropzone";
import MediaType from "types/enums/MediaType";
import WrapWithTooltip from "components/tooltips/WrapWithTooltip";
import Select from "components/inputs/select/Select";
import emptyFunction from "sps-shared/dist/utils/emptyFunction";
import { Maybe } from "sps-shared/dist/types/UtilityTypes";
import uploadFile from "utils/firebase/storage/uploadFile";
import useViewerContext from "hooks/contexts/useViewerContext";
import notifyError from "components/toast/notifyError";

function DropzoneInput() {
  const [file, setFile] = useState<Maybe<File>>(null);
  const { viewerFirebaseInfo } = useViewerContext();

  return (
    <div className={styles.flexColumn}>
      <Dropzone
        accept={{ [MediaType.ImageJpeg]: [] }}
        onDropAccepted={(files) => {
          if (files[0] != null) {
            setFile(files[0]);
          }
        }}
      >
        Choose file
      </Dropzone>
      {file != null && viewerFirebaseInfo != null && (
        <button
          onClick={async () => {
            try {
              await uploadFile(file, "testfile", viewerFirebaseInfo.uid);
              notify({ message: "Uploaded file!", type: "info" });
            } catch {
              notifyError();
            }
          }}
          type="button"
        >
          Upload
        </button>
      )}
    </div>
  );
}

function Icons() {
  return (
    <div className={styles.flexRow}>
      <WrapWithTooltip tooltipContent="Hey!">
        <ChevronUpIcon colorValue={ColorValue.Primary} />
      </WrapWithTooltip>
      <ChevronRightIcon colorValue={ColorValue.Primary} />
      <ChevronDownIcon colorValue={ColorValue.Primary} />
      <ChevronLeftIcon colorValue={ColorValue.Primary} />
    </div>
  );
}

function Inputs() {
  const [textArea, setTextArea] = useState("");
  const [textInput, setTextInput] = useState("");

  return (
    <div className={styles.flexColumn}>
      <TextArea
        maxLength={200}
        onChange={setTextArea}
        placeholder="Foo"
        value={textArea}
      />
      <TextInput
        buttonInner={<CrossIcon colorValue={ColorValue.Primary} />}
        buttonOuter={<div>wat wat wat wat</div>}
        maxLength={10}
        onChange={setTextInput}
        placeholder="Foo"
        value={textInput}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() =>
          notify({
            message: `${bytesToHumanReadableSize(Number(textInput))}`,
            type: "info",
          })
        }
      >
        Show human readable size
      </ButtonWithText>
    </div>
  );
}

function LokiButtons() {
  return (
    <div className={styles.flexRow}>
      <TextButton
        buttonThemeOrColorClass={ColorClass.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          logEvent(AnalyticsEvent.TestEventFrontend, { hello: "world" });
        }}
      >
        Log event to Loki
      </TextButton>
    </div>
  );
}

function MessageButtons() {
  return (
    <div className={styles.flexRow}>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          message({ content: "Info", type: "info" });
        }}
      >
        Message Info
      </ButtonWithText>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          message({ content: "Warning", type: "warning" });
        }}
      >
        Message Warning
      </ButtonWithText>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          message({ content: "Error", type: "error" });
        }}
      >
        Message Error
      </ButtonWithText>
    </div>
  );
}

function ModalButtons() {
  const [isBottomDrawerShown, setIsBottomDrawerShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <BottomDrawer
        isShown={isBottomDrawerShown}
        title="Testing!"
        onHide={() => setIsBottomDrawerShown(false)}
      >
        {range(12).map((i) => (
          <Header1 key={i} colorClass={ColorClass.Primary}>
            Wat
          </Header1>
        ))}
      </BottomDrawer>
      <Modal
        isShown={isModalShown}
        title="Test Modal"
        onHide={() => setIsModalShown(false)}
      >
        {range(12).map((i) => (
          <Header1 key={i} colorClass={ColorClass.Primary}>
            Wat wat wat wat wat wat
          </Header1>
        ))}
      </Modal>
      <div className={styles.flexRow}>
        <ButtonWithText
          buttonTheme={ButtonTheme.Primary}
          fontClass={FontClass.NavLink}
          onClick={() => {
            setIsBottomDrawerShown((val) => !val);
          }}
        >
          Show bottom drawer
        </ButtonWithText>
        <ButtonWithText
          buttonTheme={ButtonTheme.Primary}
          fontClass={FontClass.NavLink}
          onClick={() => {
            setIsModalShown((val) => !val);
          }}
        >
          Show modal
        </ButtonWithText>
      </div>
    </>
  );
}

function NotifButtons() {
  return (
    <div className={styles.flexRow}>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          notify({ message: "Hi", type: "info" });
        }}
      >
        Notify Info
      </ButtonWithText>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          notify({
            description: "What up",
            message: "Hi",
            type: "warning",
          });
        }}
      >
        Notify Warning
      </ButtonWithText>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          notify({
            description: "Something went wrong!",
            message: "Hi",
            type: "error",
          });
        }}
      >
        Notify Error
      </ButtonWithText>
    </div>
  );
}

function SelectSection() {
  return (
    <div className={styles.flexRow}>
      <Select
        onChange={emptyFunction}
        options={[
          { label: "Foo", value: "foo" },
          { label: "Bar", value: "bar" },
        ]}
        placeholder="Select a value"
      />
    </div>
  );
}

function SentryButtons() {
  return (
    <div className={styles.flexRow}>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          throw new Error("testing Sentry frontend");
        }}
      >
        Test Sentry (top level event)
      </ButtonWithText>
      <ButtonWithText
        buttonTheme={ButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        onClick={() => {
          logError(AnalyticsEvent.TestErrorFrontend, "A test error", {
            anError: "yes",
          });
        }}
      >
        Test Sentry (log error)
      </ButtonWithText>
    </div>
  );
}

function ToggleButtons() {
  const [isCheckboxActive, setIsCheckboxActive] = useState(false);
  const [isRadioActive, setIsRadioActive] = useState(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const [bigTogglePosition, setBigTogglePosition] = useState<0 | 1 | 2>(0);

  return (
    <>
      <div className={styles.flexRow}>
        <CheckboxButton
          isActive={isCheckboxActive}
          onClick={() => setIsCheckboxActive((val) => !val)}
        />
        <ToggleButton
          enabled={isToggleEnabled}
          onClick={() => setIsToggleEnabled((val) => !val)}
        />
        <RadioButton
          isActive={isRadioActive}
          onClick={() => setIsRadioActive((val) => !val)}
        />
      </div>
      <div className={styles.flexRow}>
        <div className={styles.bigToggleContainer}>
          <BigToggleButton
            labels={["One", "Two", "Three"]}
            onToggle={setBigTogglePosition}
            position={bigTogglePosition}
          />
        </div>
      </div>
    </>
  );
}

export default function LandingPage(): JSX.Element {
  return (
    <PageWithHeaderAndFooter hasBottomTabs>
      <PageBody hasHorizontalPadding>
        <Header3 colorClass={ColorClass.Primary}>
          Welcome to the landing page!
        </Header3>
        <div className={styles.sections}>
          <SelectSection />
          <DropzoneInput />
          <Inputs />
          <SentryButtons />
          <LokiButtons />
          <ToggleButtons />
          <NotifButtons />
          <MessageButtons />
          <ModalButtons />
          <Icons />
        </div>
      </PageBody>
    </PageWithHeaderAndFooter>
  );
}
