/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorClass {
  BackgroundOverlay = "colorBackgroundOverlay",
  CardBackground = "colorCardBackground",
  Dimmer = "colorDimmer",
  FloatingElement = "colorFloatingElement",
  Ghost = "colorGhost",
  MenuPopup = "colorMenuPopup",
  Primary = "colorPrimary",
  Red = "colorRed",
  RedHover = "colorRedHover",
  Secondary = "colorSecondary",
  Shader = "colorShader",
  SkeletonBase = "colorSkeletonBase",
  SkeletonHighlight = "colorSkeletonHighlight",
  Tertiary = "colorTertiary",
  WebsiteBackground = "colorWebsiteBackground",
  White = "colorWhite",
}

export default ColorClass;
